import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnChanges {
  @Input() content: string;
  @Input() visible = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.getElementsByClassName('content')[0].innerHTML = this.content;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      if (changes.visible.currentValue) {
        document.getElementsByTagName('body')[0].classList.add('popup-open');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('popup-open');
      }
    }
  }
}
