import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ScrollDirective } from './components/scroll/scroll.directive';
import { PopupComponent } from './components/popup/popup.component';
import { MeetTommyComponent } from './components/meet-tommy/meet-tommy.component';

@NgModule({
  declarations: [
    AppComponent,
    ScrollDirective,
    PopupComponent,
    MeetTommyComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
