import { Directive, HostListener, ElementRef, Input, AfterViewChecked, Renderer, Output, EventEmitter, OnInit } from '@angular/core';

/*
  Scroll Directive: Usage
  [offsetTop]: number [0-1]         # percentage offset for top trigger (0.2 = top edge is 20% away from screen top)
  [offsetBottom]: number [0-1]      # percentage offset for bottom trigger (0.2 = bottom edge is 20% away from screen bottom)
  [scrollClass]: string             # class to be toggle when element is in visible area
  [reset]: boolean                  # if true remove class after element is not longer visible (repeating animation)
  [bothDirection]: boolean          # if true toggle class from both scroll direction (up->down | down->up)
  (visible): boolean                # triggers if the component enters or leaves visibility area
  (percent): number                 # returns a percentage value of the visible area of the element
  */
 @Directive({
   selector: '[appScroll]',
   exportAs: 'scroll'
  })
  export class ScrollDirective implements OnInit, AfterViewChecked {
    private triggerStart: number;
    private triggerStop: number;
    private wasActivated = false;
    private visiblityEntered = false;
    private activeSet: boolean;
    private percentSet: boolean;

    @Input() offsetTop = .2;
    @Input() offsetBottom = .2;
    @Input() scrollClass = 'scroll-active';
    @Input() reset = false;
    @Input() bothDirection = false;
    @Input() debug = false;
    @Output() active = new EventEmitter<boolean>();
    @Output() percent = new EventEmitter<number>();

  constructor(private el: ElementRef, private renderer: Renderer) {}

  ngOnInit(): void {
    this.activeSet = this.active.observers.length === 1;
    this.percentSet = this.percent.observers.length === 1;
  }

  ngAfterViewChecked(): void {
    this.reset = this.bothDirection || this.reset;
    this.triggerStart = Math.max(this.el.nativeElement.offsetTop - window.innerHeight, 0) + (window.innerHeight * this.offsetTop);
    this.triggerStop = this.el.nativeElement.offsetTop + this.el.nativeElement.offsetHeight - (window.innerHeight * this.offsetBottom);
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event): void {
    if (!this.reset && this.wasActivated) {
      return;
    }
    if (this.activeSet && !this.isVisible() && this.visiblityEntered) {
      this.active.emit(false);
    }
    if (this.activeSet && this.isVisible() && !this.visiblityEntered) {
      this.active.emit(true);
    }
    if (this.isVisible()) {
      if (this.percentSet) { this.percent.emit(this.viewPercent()); }
      this.renderer.setElementClass(this.el.nativeElement, this.scrollClass, true);
      this.wasActivated = true;
      this.visiblityEntered = true;
    } else {
      this.renderer.setElementClass(this.el.nativeElement, this.scrollClass, false);
      this.visiblityEntered = false;
    }
  }

  private isVisible(): boolean {
    if (this.bothDirection) {
      return window.pageYOffset > this.triggerStart && window.pageYOffset < this.triggerStop;
    } else {
      return window.pageYOffset > this.triggerStart;
    }
  }

  // Parallax try
  private viewPercent(): number {
    const position = window.pageYOffset - this.triggerStart;
    const percent = position / (this.triggerStop - this.triggerStart);
    return percent;
  }

}
