import { Component } from '@angular/core';

@Component({
  selector: 'app-meet-tommy',
  templateUrl: './meet-tommy.component.html',
  styleUrls: ['./meet-tommy.component.scss'],
  exportAs: 'meetTommy'
})
export class MeetTommyComponent {
  private contactPanelOpen = false;

  constructor() { }

  public toggleContactPanel(): void {
    this.contactPanelOpen = !this.contactPanelOpen;
  }
}
