import { Component } from '@angular/core';
import { datenschutz } from './static/datenschutz';
import { impressum } from './static/impressum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private datenschutz: string;
  private impressum: string;
  private datenschutzVisible = false;
  private impressumVisible = false;
  private contactPanelOpen = false;

  constructor() {
    this.datenschutz = datenschutz;
    this.impressum = impressum;
  }

  private manipulateHeaderSize(percent: number) {
    console.log(percent);
  }

  private openContactPanel(): void {
    this.contactPanelOpen = true;
  }

  private toggleDatenschutz() {
    this.datenschutzVisible = !this.datenschutzVisible;
  }

  private toggleImpressum() {
    this.impressumVisible = !this.impressumVisible;
  }
}
